import { Observable } from "rxjs";
import { GuidInterface } from "../util/guid";
import { LojaInterface } from "./loja.model";

export abstract class LojaRepository {
    abstract observe(id: GuidInterface): Observable<LojaInterface | null>;
    abstract get(id: GuidInterface): Observable<LojaInterface | null>;
    abstract save(model: LojaInterface): Promise<void>;
    abstract findByAccessPoint(value: string): Observable<LojaInterface | null>
}
