import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit, OnDestroy {

  private timeout;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.timeout = setTimeout(() => this.router.navigateByUrl(''), 6000)
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }


}
